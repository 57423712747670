.sitelink-ctn {
  background-color: #000818;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  padding: 100px 0 130px;
}

.sitelink-ctn h3 {
  margin-bottom: 30px;
}

* {
  --col-height: 120px;
  --col-width: calc(var(--col-height) * 2);
  --cols: 4;
}

.sitelink-inner-ctn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  max-width: calc((var(--col-width) * var(--cols)) + 53px);
}

.sitelink-tile {
  transition: all ease-in-out 300ms;
  border: 1px solid #0074c2;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.sitelink-tile img {
  height: var(--col-height);
  transition: all ease-in-out 300ms;
}

.sitelink-tile:hover {
  border-color: #ff21d0;
  box-shadow: 0px 0px 10px 5px rgba(255, 31, 206, 0.4);
  transform: translateY(-5px);
}

.sitelink-tile:hover img {
  transform: scale(1.1);
  filter: brightness(2);
}

.tile-text {
  background-color: #0074c2;
  padding: 0px 20px;
  height: 60px;
  width: var(--col-width);
  color: #fff;
  text-align: center;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.3em;
}

.sitelink-tile:hover .tile-text {
  background-color: #ff21d0;
}

.social-link-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin-top: 50px;
}

.social-link img {
  width: 30px;
  margin: 0 20px;
  transition: transform 300ms ease-in-out;
}

.social-link:hover img {
  transform: translateY(-4px);
  filter: invert(49%) sepia(76%) saturate(7491%) hue-rotate(188deg)
    brightness(91%) contrast(101%);
}

/*********************************** MEDIA *****************************************/

@media (max-width: 800px) {
  .sitelink-ctn {
    padding: 60px 0;
  }

  .sitelink-tile:hover {
    transform: translateY(0px);
  }

  .social-link img {
    width: 25px;
    margin: 0 15px;
  }

  * {
    --col-height: 100px;
  }

  .tile-text {
    font-size: 14px;
    padding: 0px 10px;
  }
}
