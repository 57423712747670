@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

:root {
  --blue: #0074c2;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000c25;
  overflow-x: hidden;
  box-sizing: border-box;
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.8em;
}

.block {
  width: 100%;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 25px;
  /* position: absolute; */
  top: 0;
  z-index: 99;
  box-sizing: border-box;
}

.header img {
  width: 120px;
}

.hero {
  display: flex;
  align-items: center;
  margin-top: 110px;
  padding-bottom: 50px;
}

.hero-background {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 140%;
  max-width: 1900px;
  opacity: 0.2;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
}

.col-1 {
  width: 50%;
}

h1 {
  font-size: 80px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 1.2em;
  display: inline-block;
}

.hero h4 {
  padding: 2px 50px 2px 15px;
  margin: 10px 0 20px 0;
  display: inline-block;
  background: rgb(0, 116, 194);
  background: -moz-linear-gradient(
    63deg,
    rgba(0, 116, 194, 1) 50%,
    rgba(0, 116, 194, 0) 100%
  );
  background: -webkit-linear-gradient(
    63deg,
    rgba(0, 116, 194, 1) 50%,
    rgba(0, 116, 194, 0) 100%
  );
  background: linear-gradient(
    63deg,
    rgba(0, 116, 194, 1) 50%,
    rgba(0, 116, 194, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0074c2",endColorstr="#0074c2",GradientType=1);
}

.hero p {
  background-color: rgba(0, 12, 37, 0.7);
  padding: 20px;
  margin: 0;
}

.col-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-left: 60px;
}

.col-2 img {
  width: 100%;
  max-width: 500px;
}

.col-2 img {
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.gradient {
  width: 100%;
  height: 50px;
  background: rgb(27, 53, 103);
  background: -moz-linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b3567",endColorstr="#1b3567",GradientType=1);
  position: absolute;
}

.icons-ctn {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 80px auto 20px;
}

.icon-col-ctn {
  width: 10%;
  text-align: center;
  margin-bottom: 50px;
  padding: 0 10px;
  box-sizing: border-box;
}

.icon-col {
  width: 100%;
  text-align: center;
  max-width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;
  cursor: pointer;
}

.icon-col:hover {
  background-color: #0b1937;
}

.icon-col img {
  width: 40px;
  margin-bottom: 10px;
}

.icon-col h4 {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 100%;
}

.icon-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 10px;
  margin-top: auto;
}

.icon-status svg {
  width: 14px;
  height: 14px;
  fill: #b600e7;
  margin-right: 4px;
}

.icon-status-dot {
  width: 7px;
  height: 7px;
  background-color: #00bf55;
  border-radius: 20px;
  margin-right: 4px;
  margin-top: -1px;
}

h2 {
  font-size: 50px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 1.2em;
  display: inline-block;
}

h3 {
  font-size: 30px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2em;
  display: inline-block;
}

.top-text-ctn {
  text-align: center;
}

.top-text-ctn p {
  opacity: 0.4;
  margin-bottom: 50px;
  max-width: 600px;
  margin: 20px auto 50px;
}

.mini-map-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 130px;
  position: relative;
}

.minimap-block-ctn {
  position: relative;
}

.minimap-block {
  width: 60px;
  cursor: pointer;
  margin: 0 6px;
}

.milestone-helper {
  position: absolute;
  font-size: 12px;
  width: 100%;
  text-align: center;
  left: 0px;
  transition: all ease-in-out 200ms;
  background-color: #000c25;
  z-index: 99;
  padding-bottom: 5px;
}

.minimap-block-ctn:hover .milestone-helper {
  opacity: 1;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  display: inline-block;
  border-bottom: 6px solid #00bf55;
}

.minimap-dot {
  width: 4px;
  height: 10px;
  background-color: #0a4c3d;
  margin: 0 2px;
}

.here-line {
  height: 70px;
  width: 2px;
  background-color: #fff;
  margin: 0 10px;
  position: relative;
}

.here-line-text {
  position: absolute;
  width: 150px;
  display: inline-block;
  background-color: #1f2a40;
  left: -76px;
  top: -50px;
  z-index: 99;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%,
    0 0
  );
}

.here-line-text h6 {
  font-size: 14px;
  margin: 0;
}

.here-line-text p {
  font-size: 12px;
  margin: 0;
  line-height: 1.2em;
  margin-bottom: 3px;
}

.here-line-text p span {
  font-weight: 700;
  color: #00bf55;
}

.purple-arrow {
  width: 60px;
  opacity: 0.7;
}

.roadmap-ctn {
  text-align: center;
}

.roadmap-inner-ctn {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.milestone-block {
  max-width: 700px;
}

.milestone-header {
  font-size: 26px;
  font-weight: 700;
  background-color: #00bf55;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
}

.milestone-header span {
  font-size: 14px;
  line-height: 1em;
  font-weight: normal;
}

.milestone-body {
  background-color: #004233;
  margin: 0;
  padding: 20px 30px;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    0 100%,
    0 0
  );
}

.milestone-body p {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  /* font-size: 14px; */
}

.complete-date {
  display: inline-block;
  font-size: 12px;
  padding: 3px 15px;
  background-color: #00bf55;
  font-weight: 700;
}

.diffs {
  display: inline-block;
  font-size: 12px;
  padding: 5px 0px;
  margin-left: 12px;
  color: #0074c2;
  font-weight: 700;
  cursor: pointer;
}

.diffs:hover {
  text-decoration: underline;
}

.sub-item-ctn {
  padding: 30px 40px 30px 60px;
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.05); */
}

/* .sub-item-ctn::before {
  content: '';
  height: 100%;
  position: absolute;
  width: 30px;
  left: 0;
  top: 0;
  background-color: #000c25;
}

.sub-item-ctn::after {
  content: '';
  height: 100%;
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  background-color: #000c25;
} */

.sub-item-line {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #004233;
  top: 0;
  left: 30px;
}

.sub-item {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
  position: relative;
  /* padding-right: 20px; */
}

.sub-item:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0;
}

.check-circle {
  width: 26px;
  height: 26px;
  background-color: #00bf55;
  position: absolute;
  top: 0;
  left: -43px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-circle img {
  width: 60%;
}

.sub-diff-ctn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sub-diff {
  display: inline-block;
  font-size: 12px;
  padding: 5px 0px;
  color: #0074c2;
  font-weight: 700;
  cursor: pointer;
}

.sub-diff:hover {
  text-decoration: underline;
}

.sub-diff-ctn span,
.sub-underway span {
  margin: 0 6px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
}

.sub-diff-ctn span:last-child,
.sub-underway span:last-child {
  display: none;
}

.sub-underway {
  display: block;
  font-size: 12px;
  padding: 5px 0px;
  color: #b600e7;
  font-weight: 700;
}

.we-are-here-break {
  width: 100%;
  padding: 0 0 0 80px;
  height: 60px;
  font-size: 26px;
  font-weight: 700;
  color: #b600e7;
  background-color: #fff;
  max-width: 800px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.we-are-here-break::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #000c25;
  position: absolute;
  left: 0;
  top: 0;
}

.we-are-here-break::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid #000c25;
  position: absolute;
  right: 0;
  top: 0;
}

.purple-arrow2 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #b600e7;
  margin-left: 10px;
}

.future-ctn {
  margin-top: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.future-ctn p {
  opacity: 0.4;
  margin-bottom: 40px;
  max-width: 700px;
}

.future-blocks-ctn {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.future-block {
  width: 50%;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  align-items: stretch;
}

.future-block-inner {
  background-color: #0074c2;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%,
    0 0
  );
}

/* .future-block-inner:hover {
  background-color: #b600e7;
} */

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/*********************************** MODAL *****************************************/

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 13, 41, 0.8);
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  /* cursor: pointer; */
}

.modal-border {
  width: 90%;
  max-width: 900px;
  z-index: 9999;
  background-color: #273498;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  padding: 1px;
  text-align: center;
  line-height: 1.9em;
  box-sizing: border-box;
  font-size: 16px;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    0 100%,
    0 0
  );
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner {
  width: 100%;
  position: relative;
  text-align: left;
  padding: 30px;
  background-color: #020c23;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    0 100%,
    0 0
  );
}

.modal-inner h3 {
  font-size: 25px;
  margin: 0;
  margin-bottom: 15px;
  letter-spacing: -0.04em;
}

.modal-inner h5 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 15px;
}

.modal-inner p {
  margin: 0;
  opacity: 0.8;
  margin-bottom: 15px;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 10px;
}

.close-btn::after {
  content: '\00d7';
  color: rgba(255, 255, 255, 0.3);
}

.close-btn:hover::after {
  color: #fff;
}

.modal-status {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.modal-status svg {
  width: 14px;
  height: 14px;
  fill: #b600e7;
  margin-right: 4px;
}

.modal-status-dot {
  width: 8px;
  height: 8px;
  background-color: #00bf55;
  border-radius: 20px;
  margin-right: 6px;
}

.modal-status-text {
  line-height: 1.4em;
}

.learn-btn {
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  background-color: #0074c2;
  padding: 2px;
}

.learn-btn div {
  background-color: #000c25;
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  box-shadow: inset 0 0 13px 2px rgb(0 171 231 / 33%);
}

.learn-btn div:hover {
  background-color: #000c25;
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  box-shadow: inset 0 0 13px 2px rgb(0 171 231 / 100%);
}

/************************************* COUNT DOWN *************************************/

.countdown-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 400ms ease-in-out;
  box-sizing: border-box;
  /* height: 400px; */
  position: relative;
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.countdown-ctn video {
  object-fit: cover;
  width: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
}

.gradient-transition {
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  background: rgb(0, 12, 37);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000c25",endColorstr="#000c25",GradientType=1);
}

/* .countdown-ctn canvas {
  top: -60px !important;
} */

.scaledown {
  transform: scale(0.6) translateY(-60px);
  margin-bottom: -40px;
  transition: all 400ms ease-in-out;
}

.countdown-content {
  width: 100%;
  text-align: center;
}

.countdown-content h4 {
  font-size: 32px;
  margin: 0;
  margin-bottom: 10px;
}

.countdown-content p {
  max-width: 600px;
  margin: 0;
}

.countdown-content a {
  text-decoration: underline;
}

.countdown-content a:hover {
  color: #0074c2;
  text-decoration: underline;
}

.countdown-inner-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.51);
  border: 2px solid #0074c2;
  padding: 0px 60px;
  max-width: 700px;
  height: 263px;
  margin: auto;
  flex-direction: column;
  transition: all 400ms ease-in-out;
}

.countdown_outer_ctn {
  z-index: 9999;
  width: 100%;
  text-align: center;
}

.countdownContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.mtp_ctn {
  font-size: 70px;
  font-weight: bold;
  line-height: 1em;
  display: inline-block;
  text-align: center;
  font-family: 'Space Mono', monospace;
}

.tooltip {
  position: relative;
  display: inline-block;
  align-self: center;
}

.tooltip span {
  font-size: 12px;
  background-color: #0074c2;
  border-radius: 50%;
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  right: -25px;
  top: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal_text {
  font-size: 16px;
  width: 100%;
  max-width: 800px;
  background: #000c25;
  position: absolute;
  align-self: center;
  z-index: 99999;
  padding: 25px;
  border-radius: 6px;
  top: -50px;
  border: 1px solid #0074c2;
}

.modal_close {
  padding: 5px 20px;
  background: #0074c2;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
}

.countdown_outer_ctn h4 {
  font-size: 32px;
  margin: 0;
  margin-top: 20px;
}

.countdown_text_ctn {
  text-align: center;
  margin-top: 20px;
  z-index: 9999;
  position: relative;
  display: flex;
  flex-direction: column;
}

.countdown_text_ctn h4 {
  margin: 0;
  font-size: 22px;
  text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.7);
}

.countdown_text_ctn .percentage {
  display: block;
  margin: 0 0 0 0;
}
.countdown_text_ctn .percentage span {
  color: #05b4f3;
}

.countdown_text_ctn h3 {
  font-size: 42px;
  margin: -5px 0 8px;
  text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.7);
}

.countdown_link {
  padding: 6px 18px;
  background-color: #0074c2;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
  transition: all ease-in-out 300ms;
  z-index: 9999;
  position: relative;
  align-self: center;
  cursor: pointer;
}

.countdown_link:hover {
  box-shadow: 0px 0px 12px 6px rgba(255, 255, 255, 0.4);
  background-color: #00abe7;
}

.countdown-inner-ctn h5 span {
  font-family: 'Space Mono', monospace;
}

.countdownUnitContainer {
  display: inline-block;
  margin: 0;
  text-align: center;
}

.countdownUnit {
  font-size: 70px;
  font-weight: bold;
  line-height: 1em;
  display: inline-block;
  text-align: center;
  font-family: 'Space Mono', monospace;
}

.blocksremaining {
  font-size: 50px;
  font-weight: bold;
  line-height: 1em;
  display: inline-block;
  text-align: center;
  font-family: 'Space Mono', monospace;
}

.countdownLabel {
  font-size: 16px;
  margin-top: 10px;
}

.colon {
  display: inline-block;
  font-size: 50px;
  margin: -33px 10px 0 10px;
}

@media (max-width: 960px) {
  .countdown-ctn {
  }
  .countdown-ctn video {
    display: none;
  }
  .countdown-inner-ctn {
    padding: 30px 20px;
    height: unset;
  }
  .countdown-inner-ctn h5 {
    font-size: 14px;
    margin-top: 10px;
  }
  .scaledown {
    transform: scale(0.6) translateY(-30px);
    margin-bottom: -30px;
    transition: all 400ms ease-in-out;
  }
  .countdown-content h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .countdown-content p {
    line-height: 1.2em;
  }
  .countdownUnit {
    font-size: 45px;
  }

  .blocksremaining {
    font-size: 30px;
  }

  .colon {
    font-size: 30px;
    margin: -20px 5px 0 5px;
  }

  .countdownLabel {
    font-size: 14px;
    margin-top: 0px;
  }

  .countdown_text_ctn h4 {
    margin: 0;
    font-size: 18px;
    text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.7);
  }

  .countdown_text_ctn h3 {
    font-size: 35px;
    margin: 4px 0 8px;
    text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.7);
  }
}

/*********************************** Network *****************************************/

.network-ctn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  /* height: 400px; */
  /* background-color: #05122c; */
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 50px;
}

.network-ctn h3 {
  margin-bottom: 20px;
}

.nodecard-ctn {
  position: absolute;
  top: 82px;
  left: 0;
  z-index: 99999;
  margin: 5px;
}

.nodeCard {
  min-width: 300px;
  max-width: fit-content;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #d3d3d3 !important;
  z-index: 1;
  padding: 8px 10px;
  border-radius: 1px;
  position: absolute;
  /* border: 2px solid #0074c2; */
  display: flex;
  flex-direction: column;
}

.networkstat-ctn {
  display: flex;
  align-items: center;
  width: calc(100vw * 0.7);
  margin-bottom: 2px;
  position: relative;
}

.networkstat {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #002b48;
}

.networkstat:nth-child(2) {
  margin: 0 2px;
}

.networkstat span:first-child {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2em;
}

.loading-ctn {
  height: 300px;
  width: 70%;
  background-color: #002b48;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot1 {
  animation: visibility 2s linear infinite;
  margin-left: 1px;
}

@keyframes visibility {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 2s linear infinite;
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 2s linear infinite;
}

@keyframes visibility3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 950px) {
  .networkstat-ctn {
    flex-direction: column;
  }
  .networkstat:nth-child(2) {
    margin: 2px 0;
  }
  .networkstat span:first-child {
    font-size: 23px;
  }
  .networkstat {
    height: 80px;
  }
  .nodecard-ctn {
    position: absolute;
    top: 248px;
    left: 0;
    z-index: 99999;
    margin: 5px;
  }
}

/*********************************** MEDIA *****************************************/

@media (min-width: 1900px) {
  .hero-background {
    background-size: 100%;
  }
}

@media (max-width: 950px) {
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  .block {
    padding: 0 10px;
  }

  h1 {
    font-size: 50px;
  }

  .header {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header img {
    width: 100px;
  }

  .hero {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 50px;
    flex-direction: column;
  }

  .col-1 {
    width: 100%;
    text-align: center;
  }

  .hero h4 {
    padding: 2px 30px;
    font-size: 14px;
    margin: 10px 0 0px 0;
    background: none;
    background: rgb(0, 116, 194);
    background: -moz-linear-gradient(
      90deg,
      rgba(0, 116, 194, 0) 0%,
      rgba(0, 116, 194, 1) 10%,
      rgba(0, 116, 194, 1) 90%,
      rgba(0, 116, 194, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(0, 116, 194, 0) 0%,
      rgba(0, 116, 194, 1) 10%,
      rgba(0, 116, 194, 1) 90%,
      rgba(0, 116, 194, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(0, 116, 194, 0) 0%,
      rgba(0, 116, 194, 1) 10%,
      rgba(0, 116, 194, 1) 90%,
      rgba(0, 116, 194, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0074c2",endColorstr="#0074c2",GradientType=1);
  }

  .hero p {
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
  }

  .col-2 {
    width: 100%;
    padding-left: 0px;
  }

  .col-2 img {
    max-width: 300px;
  }

  .icons-ctn {
    margin: 50px auto 20px;
  }

  .icon-col-ctn {
    width: 33%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .icon-col img {
    width: 30px;
  }

  .icon-col h4 {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  h2 {
    font-size: 30px;
    letter-spacing: -0.04em;
  }

  h3 {
    font-size: 20px;
    letter-spacing: -0.04em;
  }

  .top-text-ctn p {
    margin-bottom: 30px;
  }

  .mini-map-ctn {
    margin-top: 20px;
    margin-bottom: 70px;
  }

  .milestone-helper {
    display: none;
  }

  .minimap-block {
    width: 30px;
    margin: 0 1px;
  }

  .minimap-dot {
    width: 4px;
    height: 4px;
    margin: 0 0px;
  }

  .here-line {
    height: 40px;
    width: 1px;
    margin: 0 2px;
  }

  .here-line-text {
    width: 120px;
    left: -120px;
    top: 50px;
    font-size: 12px;
    padding: 4px;
    clip-path: polygon(
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 0
    );
  }

  .purple-arrow {
    width: 15px;
    display: none;
  }
  .roadmap-inner-ctn {
    margin-top: 20px;
  }

  .milestone-header {
    font-size: 18px;
    padding: 10px 10px;
  }

  .milestone-body {
    padding: 10px 10px;
  }

  .sub-item {
    margin-bottom: 10px;
    font-size: 14px;
    padding-bottom: 10px;
  }

  /* .we-are-here-break {
    padding: 0 0 0 60px;
    height: 40px;
    font-size: 20px;
  }

  .we-are-here-break::before {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #000c25;
  }

  .we-are-here-break::after {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #000c25;
  } */
  .future-ctn {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .modal-status-text {
    max-width: 200px;
  }

  .modal_text {
    width: 90%;
    max-width: unset;
    padding: 15px;
    top: -130px;
  }

  .tooltip span {
    width: 15px;
    height: 15px;
    right: -20px;
    top: 6px;
  }
}

.latestrewards_ctn {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.reward_ctn {
  margin: 0 10px;
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.reward_ctn_line {
  margin: 0 5px;
}

.countdown_outer_ctn .latest_rewards_title {
  margin: 0;
  font-size: 22px;
  margin-top: 30px;
}

.amount {
  font-weight: 600;
  font-size: 20px;
}

.paid_to {
  font-size: 12px;
}

@media (max-width: 960px) {
  .latestrewards_ctn {
    flex-direction: column;
  }

  .reward_ctn {
    margin: 10px 0;
    width: 90%;
    border-bottom: 1px solid #ffffff50;
    padding-bottom: 20px;
  }

  .reward_ctn:last-child {
    border-bottom: none;
  }
}

.leaderboard-ctn {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000070;
  z-index: 999999;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lbtable {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #000c25;
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
}

.lbrow {
  display: flex;
}

.lbheader {
  font-weight: bold;
  background-color: #0074c2;
}

.lbcell {
  flex: 1;
  padding: 8px;
  border: 1px solid rgb(11, 29, 67);
}

.cell1 {
  flex: 1;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #000c25;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #0074c2;
  height: 50px;
}
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

.lb-close {
  cursor: pointer;
  max-width: 600px;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}
